/** @jsx jsx */
import { darken, alpha } from "@theme-ui/color";
import { jsx } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";


const headerStyle1 = {
  color: darken("text", 0.25),
  bg: alpha("background", 0.7),
  borderRadius: "5px",
  mx: 0,
  px: [1, 2, 3],
  py: 1,
};


export default function HeaderImage({ pageHeader, positionTop }) {

  const { tag, value } = pageHeader;

  let headerTextComp
  switch (tag) {
    case "h1":
      headerTextComp = (<h1 sx={headerStyle1}>
        {value}
      </h1>)
      break;

    case "h2":
      headerTextComp = (<h2 sx={headerStyle1}>
        {value}
      </h2>)
      break;

    case "h3":
      headerTextComp = (<h3 sx={headerStyle1}>
        {value}
      </h3>)
      break;

    case "div":
      headerTextComp = (<div sx={{ ...headerStyle1, variant: 'styles.h3' }}>
        {value}
      </div>)
      break;

    default:
      break;
  }

  return (

    <div
      id="wrapHeaderImg"
      style={{ display: "grid", maxHeight: "300px", }}
    >
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "inherit",
        }}
        src="../../images/shared/fluid/head1_big.jpg"
        alt="Kamery - header"
        title="Kamery - header"
        layout="fullWidth"
        placeholder="blurred"
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
          maxHeight: "inherit",
          textAlign: "center",
        }}
      >
        {headerTextComp}
      </div>
    </div>
  )
}
